import { useMemo } from "react";
import { css, useTheme } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import { getTheme } from "@sablier/v2-themes";
import { lighten, rgba } from "polished";
import type { ThemeType } from "@sablier/v2-themes";

/**
 * ⚙️ Notes on configuration
 * --------------------------------------------
 * Accents
 * - base accents will be derived from color and gradient names
 * - for custom accents (🧠) please add your keys in the "accents" object below (this is how we infer them as types)
 */

const accents = {
  fail: "fail",
  failLight: "failLight",
  fail300: "fail300",
  fail400: "fail400",
  dark200Primary: "dark200Primary",
  hot: "hot",
  iconic: "iconic",
  iconic400: "iconic400",
  iconic500: "iconic500",
  iconicLabel: "iconicLabel",
  iconicPassive: "iconicPassive",
  iconicPassiveTransparent: "iconicPassiveTransparent",
  iconicPrimary: "iconicPrimary",
  iconicPrimary400: "iconicPrimary400",
  iconicSecondary: "iconicSecondary",
  iconicPurple: "iconicPurple",
  iconicTransparent: "iconicTransparent",
  input: "input",
  secondaryNeon: "secondaryNeon",
  primaryNeon: "primaryNeon",
  purpleNeon: "purpleNeon",
  purpleActive: "purpleActive",
  redNeon: "redNeon",
  tableMenu: "tableMenu",
  warning: "warning",
  warningLight: "warningLight",
  warning200: "warning200",
  warning300: "warning300",
  warning400: "warning400",
  whiteNeon: "whiteNeon",
  whiteActive: "whiteActive",
  whitePrimary: "whitePrimary",
  whiteSecondary: "whiteSecondary",
};

/**
 * --------------------------------------------
 */

const dark = getTheme("dark");

export type AccentKey =
  | keyof ThemeType["colors"]
  | keyof ThemeType["gradients"]
  | keyof typeof accents;

export const configuration = {
  appearance: {
    solid: "solid",
    outline: "outline",
    gradient: "gradient",
    transparent: "transparent",
  },
  accent: {
    ...Object.keys(dark.colors).reduce((p, c) => ({ ...p, [c]: c }), {}),
    ...Object.keys(dark.gradients).reduce((p, c) => ({ ...p, [c]: c }), {}),
    ...Object.keys(accents).reduce((p, c) => ({ ...p, [c]: c }), {}),
  } as { [key in AccentKey]: AccentKey },
  purpose: {
    button: "button",
    internal: "internal",
    external: "external",
  },
};

export type AppearanceKey = keyof typeof configuration.appearance;
export type PurposeKey = keyof typeof configuration.purpose;

export interface Elements {
  background?: string;
  backgroundHover?: string;
  border?: string;
  borderHover?: string;
  color?: string;
  colorHover?: string;
}

export interface Design extends Elements {
  accent: AccentKey;
  appearance: AppearanceKey;
}

const baseSolid = (background: string, content: string): Elements => ({
  background,
  backgroundHover: lighten(0.05, background),
  border: undefined,
  borderHover: undefined,
  color: content,
  colorHover: content,
});

const baseGradient = (background: string, content: string): Elements => ({
  ...baseSolid(dark.colors.dark000, content),
  background,
  backgroundHover: undefined,
});

const baseOutline = (
  background: string | undefined,
  border: string,
  content: string,
): Elements => ({
  ...baseSolid(background || "transparent", content),
  border,
  borderHover: undefined,
  backgroundHover: background ? lighten(0.05, background) : dark.colors.dark100,
});

export const useDesigner = (
  accent: AccentKey,
  appearance: AppearanceKey,
): Design => {
  const theme = useTheme() as ThemeType;
  const { colors, gradients } = theme;

  const elements = useMemo(() => {
    switch (appearance) {
      case configuration.appearance.solid: {
        switch (accent) {
          case configuration.accent.gray100:
            return baseSolid(colors.gray100, colors.dark400);
          case configuration.accent.gray200:
            return baseSolid(colors.gray200, colors.dark400);
          case configuration.accent.fail:
          case configuration.accent.failLight:
            return baseSolid(colors.dark200, colors.red);
          case configuration.accent.dark200Primary:
            return baseSolid(colors.dark200, colors.primaryMiddle);
          case configuration.accent.fail300:
            return baseSolid(colors.dark300, colors.red);
          case configuration.accent.fail400:
            return baseSolid(colors.dark400, colors.red);
          case configuration.accent.hot:
            return baseSolid(colors.dark100, colors.orange);
          case configuration.accent.iconicLabel:
            return baseSolid(colors.dark300, colors.gray400);
          case configuration.accent.iconicPrimary:
            return baseSolid(colors.dark200, colors.primaryMiddle);
          case configuration.accent.iconicPassive:
            return baseSolid(colors.dark200, colors.gray400);
          case configuration.accent.iconicPrimary400:
            return baseSolid(colors.dark400, colors.primaryMiddle);
          case configuration.accent.iconicPurple:
            return baseSolid(colors.dark200, colors.purpleMiddle);
          case configuration.accent.input:
            return baseSolid(colors.dark200, colors.gray400);
          case configuration.accent.yellow:
            return baseSolid(colors.yellow, colors.white);
          case configuration.accent.warning:
          case configuration.accent.warningLight:
            return baseSolid(colors.dark200, colors.yellow);
          case configuration.accent.warning200:
            return baseSolid(colors.dark200, colors.yellow);
          case configuration.accent.warning300:
            return baseSolid(colors.dark300, colors.yellow);
          case configuration.accent.warning400:
            return baseSolid(colors.dark400, colors.yellow);
          case configuration.accent.whitePrimary:
            return baseSolid(colors.white, colors.primaryMiddle);
          case configuration.accent.whiteSecondary:
            return baseSolid(colors.white, colors.secondaryMiddle);
          case configuration.accent.white:
            return baseSolid(colors.white, colors.dark400);
          case configuration.accent.whiteNeon:
            return baseSolid(colors.white10, colors.white);
          case configuration.accent.primaryNeon:
            return {
              ...baseSolid(colors.primary10, colors.primaryMiddle),
              backgroundHover: colors.primary20,
            };

          case configuration.accent.purpleNeon:
            return {
              ...baseSolid(colors.purple10, colors.purple),
              backgroundHover: colors.purple20,
            };
          case configuration.accent.secondaryNeon:
            return {
              ...baseSolid(colors.secondary10, colors.secondaryDesaturated),
              backgroundHover: colors.secondary30,
            };

          default: {
            const background = _.get(colors, accent) ?? colors.dark000;
            const content = colors.gray100;
            return baseSolid(background, content);
          }
        }
      }
      case configuration.appearance.gradient: {
        const background = _.toString(
          _.get(gradients, accent) ?? gradients.primary,
        );
        const content = colors.white;
        return baseGradient(background, content);
      }
      case configuration.appearance.outline: {
        switch (accent) {
          case configuration.accent.fail:
            return baseOutline(colors.dark200, colors.yellow, colors.red);
          case configuration.accent.failLight:
            return baseOutline(colors.dark200, colors.dark300, colors.red);
          case configuration.accent.fail300:
            return baseOutline(colors.dark200, colors.dark300, colors.red);
          case configuration.accent.fail400:
            return baseOutline(colors.dark300, colors.dark400, colors.red);
          case configuration.accent.iconic:
            return baseOutline(colors.dark200, colors.dark300, colors.gray100);

          case configuration.accent.iconicPassive:
            return baseOutline(colors.dark200, colors.dark300, colors.gray400);
          case configuration.accent.iconicPassiveTransparent:
            return {
              ...baseOutline(
                colors.transparent,
                colors.dark300,
                colors.gray400,
              ),
              backgroundHover: rgba(colors.black, 0.15),
            };
          case configuration.accent.iconic400:
            return baseOutline(colors.dark300, colors.dark400, colors.gray100);
          case configuration.accent.iconic500:
            return baseOutline(colors.dark400, colors.dark500, colors.gray100);
          case configuration.accent.iconicPrimary:
            return baseOutline(
              colors.dark200,
              colors.dark300,
              colors.primaryMiddle,
            );
          case configuration.accent.iconicPrimary400:
            return baseOutline(
              colors.dark300,
              colors.dark400,
              colors.primaryMiddle,
            );
          case configuration.accent.iconicPurple:
            return baseOutline(
              colors.dark200,
              colors.dark300,
              colors.purpleMiddle,
            );
          case configuration.accent.iconicSecondary:
            return baseOutline(
              colors.dark200,
              colors.dark300,
              colors.secondaryDesaturated,
            );
          case configuration.accent.iconicTransparent:
            return {
              ...baseOutline(
                colors.transparent,
                colors.dark300,
                colors.gray100,
              ),
              backgroundHover: colors.dark400,
            };
          case configuration.accent.primaryNeon:
            return baseOutline(
              undefined,
              colors.primaryMiddle,
              colors.primaryMiddle,
            );
          case configuration.accent.purpleNeon:
            return baseOutline(
              undefined,
              colors.purpleMiddle,
              colors.purpleMiddle,
            );
          case configuration.accent.purple:
            return {
              ...baseOutline(colors.transparent, colors.purple, colors.purple),
              backgroundHover: rgba(colors.purple, 0.1),
            };
          case configuration.accent.purpleActive:
            return {
              ...baseOutline(colors.purple10, colors.purple, colors.purple),
              backgroundHover: rgba(colors.purple, 0.3),
            };
          case configuration.accent.whiteActive:
            return {
              ...baseOutline(colors.white10, colors.white, colors.white),
              backgroundHover: rgba(colors.white, 0.3),
            };
          case configuration.accent.secondaryNeon:
            return baseOutline(
              undefined,
              colors.secondaryMiddle,
              colors.secondaryMiddle,
            );
          case configuration.accent.redNeon:
            return baseOutline(colors.transparent, colors.red, colors.red);
          case configuration.accent.warning:
            return {
              ...baseOutline(colors.transparent, colors.yellow, colors.yellow),
              backgroundHover: rgba(colors.black, 0.15),
            };
          case configuration.accent.warningLight:
            return {
              ...baseOutline(colors.transparent, colors.dark300, colors.yellow),
              backgroundHover: rgba(colors.black, 0.15),
            };
          case configuration.accent.warning400:
            return {
              ...baseOutline(colors.transparent, colors.dark400, colors.yellow),
              backgroundHover: rgba(colors.black, 0.15),
            };
          case configuration.accent.dark700:
            return baseOutline(colors.dark200, colors.dark700, colors.gray200);
          case configuration.accent.secondaryDesaturated:
            return baseOutline(
              colors.dark200,
              colors.secondaryDesaturated,
              colors.secondaryDesaturated,
            );
          default: {
            const background = undefined;
            const border = _.get(colors, accent) ?? colors.gray100;

            const content = colors.gray100;
            return baseOutline(background, border, content);
          }
        }
      }
      case configuration.appearance.transparent: {
        const background = colors.transparent;
        const content = _.get(colors, accent) ?? colors.gray100;
        switch (accent) {
          case configuration.accent.tableMenu:
            return {
              ...baseSolid(background, colors.white),
              colorHover: colors.gray400,
            };
          case configuration.accent.warning:
            return {
              ...baseSolid(background, colors.yellow),
              colorHover: lighten(0.2, colors.yellow),
            };
          default:
            return {
              ...baseSolid(background, content),
              colorHover: lighten(0.1, content),
            };
        }
      }
    }

    return baseSolid(colors.dark000, colors.white);
  }, [accent, appearance, colors, gradients]);

  const design = useMemo(() => {
    return {
      ...elements,
      accent,
      appearance,
    };
  }, [accent, appearance, elements]);

  return design;
};

export function useStyler(design: Design) {
  const style = useMemo(() => {
    const test = (v: keyof Design) => !_.isNilOrEmptyString(_.get(design, v));

    switch (design.appearance) {
      case configuration.appearance.outline:
      case configuration.appearance.transparent:
      case configuration.appearance.solid:
        return css`
          ${test("color") && `color: ${design.color};`}
          ${test("background") && `background: ${design.background};`}
            ${test("border") && `border: 2px solid ${design.border};`}
            transition: background 100ms, box-shadow 100ms, outline 100ms, color 100ms;

          &:not([data-preview="true"]):not([data-disabled="true"]) {
            &:hover,
            &:active,
            &[data-loading="true"] {
              ${test("colorHover") && `color: ${design.colorHover};`}
              ${test("backgroundHover") &&
              `background: ${design.backgroundHover};`}
                ${test("borderHover") &&
              `border: 2px solid ${design.borderHover};`}
                transition: background 100ms, box-shadow 100ms, outline 100ms, color 100ms;
            }
          }
        `;
      case configuration.appearance.gradient:
        return css`
          ${test("color") && `color: ${design.color};`}
          ${test("background") && `background: ${design.background};`}
          ${test("border") && `border: 2px solid ${design.border};`}

          background-position-x: 0%;
          background-position-y: 0%;
          background-size: 100% 100%;

          transition: background 100ms, box-shadow 100ms, outline 100ms,
            color 100ms;
          &:not([data-preview="true"]):not([data-disabled="true"]) {
            &:hover,
            &:active,
            &[data-loading="true"] {
              ${test("colorHover") && `color: ${design.colorHover};`}
              ${test("borderHover") &&
              `border: 2px solid ${design.borderHover};`}

                background-size: 200% 100%;

              transition: background-size 100ms, box-shadow 100ms, outline 100ms,
                color 100ms;
            }
          }
        `;
      default:
        return css``;
    }
  }, [design]);

  return style;
}
